import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicing } from '../../../../../../libs/env/src/index';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { envServicingQa } from '../../../../../../libs/env/src/index';
import { env as baseEnv } from './env';

/** DON'T USE IN ANY MODULES. THIS IS WHAT `@ecp/env` ALIAS RESOLVES TO AT BUILD TIME. */
export const env: EnvironmentServicing = merge({}, envServicingQa, baseEnv, {
  optimizely: {
    sdkKey: 'M6BZ4SdPGwrHc83odzkZm',
  },
  salesAppBaseUrl: 'https://qa.homesitep2.com/costco/sales/index.htm',
}) as EnvironmentServicing;
